import React, { Component, createRef } from "react";
import KaisakuApi from "../../../controllers/kaisaku-api";
import { connect } from "react-redux";
import AndroidComm from "../../../controllers/utilities/unity-android-communication";
import View from "./login-form-view";
import withTrans from "../../../i18n/withTrans";
import {
    Button, Form, Header, Modal, GridColumn, Image,
    GridRow,
} from "semantic-ui-react";
import { navigate } from "../../../../.cache/gatsby-browser-entry";
import { environmentMode, getAdItem } from "../../lib/ad-manage-api-lib";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import btnsaveimage from "../../../images/login/saveimage.png";
import btnbackgame from "../../../images/login/backgame.png";
import submit from "../../../images/login/submit.png";
import vcode from "../../../images/login/vcode.png";
import btnback from "../../../images/main/back.png";
import i18next from "i18next";

export class LoginForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            username: "",
            password: "",
            errorMsgs: [],
            loading: true,
            success: false,
            adItem: {
                success: false,
                adId: '',
                eventId: '',
                adImage: '',
                adUrl: '',
                adAImage: '',
                adAUrl: '',
                adBImage: '',
                adBUrl: '',
                adCImage: '',
                adCUrl: ''
            },
            quickSignupResult: null
        };
    }

    componentDidMount() {
        this.setState({
            loading: !!this.props.session
        });

        if (this.props.session) {
            KaisakuApi.refreshSession(this.props.session, this.props.attributionId, this.props.gameId).then(res => {
                this.processResult(res);
            });
        }
    }

    onUsernameChange(e, data) {
        this.setState({ username: data.value });
    }

    onPasswordChange(e, data) {
        this.setState({ password: data.value });
    }

    onCaptchaCodeChange(e, data) {
        this.setState({ captchacode: data.value, errorMsgs: [] });
    }

    onReturn() {
        this.setState({
            loading: false,
            captchaid: "",
            errorMsgs: []
        });
    }

    async onSubmit2() {
        this.setState({
            loading: true,
            success: false,
            errorMsgs: []
        });
        if (!this.state.captchacode) {
            this.setState({
                errorMsgs: [i18next.t("SDK.Pleaseentertheverificationcode")]
            });
            return;
        }
        const res = await KaisakuApi.quickSignUp(this.props.attributionId, this.props.gameId, this.state.captchaid, this.state.captchacode);
        this.processResult(res);
    }


    async onSubmit() {
        this.setState({
            loading: true,
            success: false,
            errorMsgs: []
        });
        const res = await KaisakuApi.login(this.state.username, this.state.password, this.props.attributionId, this.props.gameId);

        this.processResult(res);
    }

    async quickSignUp() {
        this.setState({
            loading: true,
            success: false,
            errorMsgs: []
        });
        // 提交，获取验证码
        const res = await KaisakuApi.quickSignUpCaptcha(this.props.attributionId, this.props.gameId);
        // console.log(res);
        if (res.success && res.data.captchaid) {
            this.setState({
                captchaid: res.data.captchaid,
                captchaimg: 'data:image/png;base64,' + res.data.img
            });
        } else {
            const res = await KaisakuApi.quickSignUp(this.props.attributionId, this.props.gameId);
            this.processResult(res);
        }
    }

    async toNextUrl(result, url, search) {
        // console.log(url);
        // console.log(search);
        if (result.session.showBindMail) {  
            navigate("/account-configs-page1/" + search + "&nurl=" + url);
        } else {
            navigate("/" + url + search);
        }

    }

    async processResult(result) {
        console.log(result);
        if (result.success) {
            const { accessToken, gameId, userId } = result.session;
            let adItem1 = { success: false };
            let isIOS = "0";
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) === true) {  //判断iPhone|iPad|iPod|iOS
                // 暂时去掉ios两板功能
                isIOS = "1";
            }
            // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) === false) {  //判断iPhone|iPad|iPod|iOS
            //     adItem1 = await getAdItem(userId, 1, isIOS);
            // }
            if ((this.props.gameId !== "d074e9aa-ab68-447f-9c69-ac6f04191cf3")
                // && (this.props.gameId !== "bbec58a5-e7e1-4a19-9fd1-dc05e5b58f95")
                && (this.props.gameId !== "98b345ba-79b6-4209-88ff-87ba865ddf54")
                && (this.props.gameId !== "0b78cded-f7ef-4ba1-a32b-d22c1e001a97")
            ) {  // 指定gameid 不显示登入弹出广告
                if (!result.session.isQuickSignUp) {
                    adItem1 = await getAdItem(userId, 1, isIOS, this.props.gameId);
                }
            }

            if ((isIOS === "0") && (
                (this.props.gameId === "98b345ba-79b6-4209-88ff-87ba865ddf54")
                || (this.props.gameId === "0b78cded-f7ef-4ba1-a32b-d22c1e001a97")
            )) {
                if (!result.session.isQuickSignUp) {
                    adItem1 = await getAdItem(userId, 1, isIOS, this.props.gameId);
                }
            }

            console.log(adItem1);
            this.setState({
                adItem: adItem1
            });
            console.log("111");
            console.log(result.session);
            if (result.session.quickSignUpCredentials) {
                this.setState({
                    quickSignUpSession: result.session
                });
            } else {
                let url = "";
                let search = "";
                if (this.state.adItem.success === false) {
                    // 跳转到广告页面吧
                    // 获取广告数据  无广告直接返回
                    // 测试先注释掉
                    // 飞机杯加的代码
                    // if (this.props.gameId === "bbec58a5-e7e1-4a19-9fd1-dc05e5b58f95"){
                    //     const res = await KaisakuApi.getInfoForM1(accessToken);
                    //     if (res.success === true) {
                    //         let nickname = result.session.nickname;
                    //         let createdAt = result.session.createdAt;
                    //         let expireAt = result.session.expireAt;
                    //         let expireAt_TTL = result.session.expireAt_TTL;
                    //         if (res.item.status === "SALE"){
                    //             navigate(`/xr-saleing-page1?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&m1=${res.item.maxCount}&m2=${res.item.saleCount}`);
                    //         }else{
                    //             navigate(`/xr-saleing-page4?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&ids=${res.ids}&message=${res.message}`);
                    //         }
                    //     }else{
                    //         AndroidComm.sendSession(result.session);
                    //     }
                    // }else {
                    //     AndroidComm.sendSession(result.session);
                    // }
                    // 去掉飞机杯
                    // AndroidComm.sendSession(result.session); // 无广告也要进入银联页
                    let nickname = result.session.nickname;
                    let createdAt = result.session.createdAt;
                    let expireAt = result.session.expireAt;
                    let expireAt_TTL = result.session.expireAt_TTL;
                    let clientIp = result.session.clientIp;
                    let updatedAt = result.session.updatedAt;
                    url = `unionpay-page1`;
                    search = `?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}&re=6`;

                } else {
                    console.log(result.session);
                    // 有广告 跳到广告页面
                    console.log("isCnIP = " + this.state.adItem.isCnIP);
                    let nickname = result.session.nickname;
                    let createdAt = result.session.createdAt;
                    let expireAt = result.session.expireAt;
                    let expireAt_TTL = result.session.expireAt_TTL;
                    let clientIp = result.session.clientIp;
                    let updatedAt = result.session.updatedAt;

                    if (this.state.adItem.isCnIP) {
                        if (isIOS === "1") {
                            url = `sdk-ad-ios`;
                            search = `?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}`;
                        } else {
                            url = `sdk-ad`;
                            search = `?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}`;
                        }
                    } else {
                        if (isIOS === "1") {
                            url = `sdk-ad-ios`;
                            search = `?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}`;
                        } else {
                            url = `sdk-ad`;
                            search = `?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}`;
                        }
                    }
                }
                this.toNextUrl(result, url, search);
            }
        } else {
            this.setState({
                loading: false,
                success: result.success,
                errorMsgs: result.errorMsgs.map(this.props.t)
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <View
                    loading={this.state.loading}
                    success={this.state.success}
                    errorMsgs={this.state.errorMsgs}
                    onUsernameChange={this.onUsernameChange.bind(this)}
                    onPasswordChange={this.onPasswordChange.bind(this)}
                    quickSignUp={this.quickSignUp.bind(this)}
                    onSubmit={this.onSubmit.bind(this)}
                    quickSignupResult={this.state.quickSignupResult}
                    gameId={this.props.gameId}
                />
                {this.state.quickSignUpSession && <QuickSignupModal session={this.state.quickSignUpSession} adItem={this.state.adItem} />}
                {this.state.captchaid && <QuickSignupModal1 img={this.state.captchaimg} errorMsgs={this.state.errorMsgs}
                    onCaptchaCodeChange={this.onCaptchaCodeChange.bind(this)}
                    quickSignUp={this.quickSignUp.bind(this)}
                    onSubmit={this.onSubmit2.bind(this)}
                    onReturn={this.onReturn.bind(this)}
                    captchacode={this.state.captchacode}
                ></QuickSignupModal1>}
            </React.Fragment>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(withTrans(LoginForm));

class QuickSignupModal1 extends Component {
    constructor(props, context) {
        super(props, context);
        this.trigger = createRef();
    }

    render() {
        const {
            onCaptchaCodeChange,
            quickSignUp,
            onSubmit,
            onReturn,
            errorMsgs,
            captchacode,
        } = this.props;
        return (
            <Modal
                size={"mini"}
                open
            >
                <Modal.Header style={{ backgroundColor: "#ca210c", color: "#FFFFFF" }}>
                    <Image style={{
                        marginBottom: "2vh",
                        height: "2vh",
                        position: "absolute",
                        top: "1.4vh",
                        left: "2vw"
                    }}
                        onClick={onReturn}
                        centered src={btnback} />
                    <div style={{ paddingLeft: "10%" }}>{i18next.t("SDK.Pleaseentertheverificationcode")}</div>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {(errorMsgs.length > 0) && (<div style={{ color: "red", paddingTop: "30px", width: "100%", textAlign: "center" }}>{(errorMsgs.length > 0) ? ((captchacode) ? i18next.t("SDK.Theverificationcodewasenteredincorrectly") : i18next.t("SDK.Pleaseentertheverificationcode")) : ""} &nbsp;</div>)}
                        <div style={{
                            padding: "10px", height: "4.2rem",
                            backgroundImage: `url(${vcode})`, backgroundSize: '100% 100%'
                        }}>
                            <div style={{ float: "left", paddingLeft: "15%" }}>
                                <Image style={{ width: "100%", height: "90%" }}
                                    src={this.props.img}
                                    onClick={quickSignUp}
                                />
                            </div>
                            <div style={{ float: "right", width: "50%", paddingRight: "15%" }}>
                                <Form.Input
                                    style={{
                                        width: "100%", height: "90%",
                                        marginTop: "-3px",
                                        marginLeft: "15%",
                                        backgroundColor: "#FFF", color: "#333"
                                    }}
                                    onChange={onCaptchaCodeChange} maxLength="4" />
                            </div>
                        </div>
                        <div style={{ fontSize: "10px", color: "#AAA", width: "100%", textAlign: "center" }}>
                            {i18next.t("SDK.Clickonthepicturetorefreshtheverificationcodeoncemore")}
                        </div>
                    </Modal.Description>
                    <Modal.Actions style={{ textAlign: "center" }}>
                        <Button
                            style={{
                                height: "4.2rem",
                                marginLeft: "0rem",
                                background: "transparent",
                                backgroundImage: `url(${submit})`, backgroundSize: '100% 100%',
                                width: "100%"
                            }}
                            size='large'
                            onClick={onSubmit}>
                            &nbsp;
                        </Button>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>)
    };
}

class QuickSignupModal extends Component {
    render() {
        let isIOS = "0";
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
            // 暂时去掉ios两板功能
            isIOS = "1";
        }
        const {
            quickSignUpCredentials: {
                username, password
            },
            isQuickSignUp,
            ...session
        } = this.props.session;
        let showTextAd = {
            display: ''
        };
        if (environmentMode === "SANDBOX") showTextAd = {
            display: 'none'
        };
        return (
            <Modal
                size={"mini"}
                open
            >
                <Modal.Header style={{ backgroundColor: "#ca210c", color: "#FFFFFF" }}>
                    {i18next.t("SDK.Registeryouraccountinformationnow")}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div id="QR">
                            <Header>{i18next.t("SDK.Pleasetakeascreenshotofthispage")}</Header>
                            <p style={{ fontWeight: "bold" }}>
                                {i18next.t("SDK.Username")}: {username}
                            </p>
                            <p style={{ fontWeight: "bold" }}>
                                {i18next.t("SDK.Password")}: {password}
                            </p>
                            <p style={showTextAd}>
                                {i18next.t("SDK.Pleasenote")}
                            </p>
                        </div>
                    </Modal.Description>
                    <Modal.Actions >

                        {
                            (isIOS === "0") && (<Button id='aId'
                                style={{
                                    width: "49%",
                                    height: "4rem",
                                    marginLeft: "-0.5rem",
                                    background: "transparent",
                                    backgroundImage: `url(${btnsaveimage})`, backgroundSize: '100% 100%'
                                }}
                                onClick={() => {
                                    window.location.href = `/downloadimage/?data=${username},${password}&r=download`;
                                }}
                            >&nbsp;</Button>)
                        }
                        <Button
                            style={{
                                width: "49%",
                                height: "4em",
                                marginRight: "-0.5rem",
                                background: "transparent",
                                backgroundImage: `url(${btnbackgame})`, backgroundSize: '100% 100%'
                            }}
                            onClick={async () => {
                                // const { accessToken, gameId, userId } = session;
                                // let isIOS = "0";
                                // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) === true) {  //判断iPhone|iPad|iPod|iOS
                                //     // 暂时去掉ios两板功能
                                //     isIOS = "1";
                                // }
                                // let adItem = this.props.adItem;
                                // if (adItem.success === false) {
                                //     AndroidComm.sendSession(session);
                                // }else {
                                //     console.log("isCnIP = " + adItem.isCnIP);
                                //     console.log(session);
                                //     let nickname = session.nickname;
                                //     let createdAt = session.createdAt;
                                //     let expireAt = session.expireAt;
                                //     let expireAt_TTL = session.expireAt_TTL;
                                //
                                //     if (isIOS === "1") {
                                //         navigate(`/sdk-ad-ios?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}`);
                                //     } else {
                                //         navigate(`/sdk-ad?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}`);
                                //     }
                                //
                                // }
                                // const { accessToken, gameId, userId } = session;
                                // if (gameId === "bbec58a5-e7e1-4a19-9fd1-dc05e5b58f95"){
                                //     const res = await KaisakuApi.getInfoForM1(accessToken);
                                //     if (res.success === true) {
                                //         let nickname = session.nickname;
                                //         let createdAt = session.createdAt;
                                //         let expireAt = session.expireAt;
                                //         let expireAt_TTL = session.expireAt_TTL;
                                //         if (res.item.status === "SALE"){
                                //             navigate(`/xr-saleing-page1?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&m1=${res.item.maxCount}&m2=${res.item.saleCount}`);
                                //         }else{
                                //             navigate(`/xr-saleing-page4?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&message=${res.message}`);
                                //         }
                                //     }else{
                                //         AndroidComm.sendSession(session);
                                //     }
                                // }else {
                                //     AndroidComm.sendSession(session);
                                // }
                                // 去掉飞机杯
                                // AndroidComm.sendSession(session);
                                const { accessToken, gameId, userId } = session;
                                let adItem = { success: false };
                                let isIOS = "0";
                                if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) === true) {  //判断iPhone|iPad|iPod|iOS
                                    // 暂时去掉ios两板功能
                                    isIOS = "1";
                                }
                                // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) === false) {  //判断iPhone|iPad|iPod|iOS
                                //     adItem = await getAdItem(userId, 1, isIOS);
                                // }
                                // if ((this.props.gameId !== "d074e9aa-ab68-447f-9c69-ac6f04191cf3"))
                                // // && (this.props.gameId !== "bbec58a5-e7e1-4a19-9fd1-dc05e5b58f95")
                                // {  // 指定gameid 不显示登入弹出广告
                                //     // adItem = await getAdItem(userId, 1, isIOS);
                                // }
                                if ((this.props.gameId !== "d074e9aa-ab68-447f-9c69-ac6f04191cf3")
                                    // && (this.props.gameId !== "bbec58a5-e7e1-4a19-9fd1-dc05e5b58f95")
                                    && (this.props.gameId !== "98b345ba-79b6-4209-88ff-87ba865ddf54")
                                    && (this.props.gameId !== "0b78cded-f7ef-4ba1-a32b-d22c1e001a97")
                                ) {  // 指定gameid 不显示登入弹出广告
                                    // if (!result.session.isQuickSignUp) {
                                    adItem = await getAdItem(userId, 1, isIOS, this.props.gameId);
                                    // }
                                }

                                if ((isIOS === "0") && (
                                    (this.props.gameId === "98b345ba-79b6-4209-88ff-87ba865ddf54")
                                    || (this.props.gameId === "0b78cded-f7ef-4ba1-a32b-d22c1e001a97")
                                )) {
                                    // if (!result.session.isQuickSignUp) {
                                    adItem = await getAdItem(userId, 1, isIOS, this.props.gameId);
                                    // }
                                }
                                if (adItem.success === false) {
                                    // AndroidComm.sendSession(session);  // 无广告也要进入银联页
                                    let nickname = session.nickname;
                                    let createdAt = session.createdAt;
                                    let expireAt = session.expireAt;
                                    let expireAt_TTL = session.expireAt_TTL;
                                    let clientIp = session.clientIp;
                                    let updatedAt = session.updatedAt;
                                    navigate(`/unionpay-page1?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}&re=6`);
                                } else {
                                    // 新用户首次登录不弹广告，这里就不会来到了
                                    console.log(session);
                                    let nickname = session.nickname;
                                    let createdAt = session.createdAt;
                                    let expireAt = session.expireAt;
                                    let expireAt_TTL = session.expireAt_TTL;
                                    let clientIp = session.clientIp;
                                    let updatedAt = session.updatedAt;

                                    if (isIOS === "1") {
                                        navigate(`/sdk-ad-ios?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}`);
                                    } else {
                                        navigate(`/sdk-ad?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}`);
                                    }
                                }
                            }}>&nbsp;</Button>

                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        );
    }
}

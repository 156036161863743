import React from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Form,
    Grid,
    GridColumn,
    GridRow,
    Image,
    Message
} from "semantic-ui-react";
import {PasswordField, UsernameEmailField} from "../form-fields";
import {Link} from "gatsby";
import withTrans from "../../../i18n/withTrans";

import iconpwd from "../../../images/login/pwd.png";
import iconlogin from "../../../images/login/login.png";
import iconline from "../../../images/login/line.png";
import icon1 from "../../../images/login/quick.png";
import icon2 from "../../../images/login/sign.png";
import input_back from "../../../images/login/input_back.png";


function LoginFormView(props) {
    const {
        loading,
        success,
        errorMsgs,
        onUsernameChange,
        onPasswordChange,
        onSubmit,
        quickSignUp,
        gameId
    } = props;
    return (
        <Container fluid>
            <Form size={"small"} loading={loading} success={success}
                  error={errorMsgs.length > 0}>
                <Card fluid style={{
                    boxShadow: '0 1px 1px 0 transparent, 0 0 0 1px transparent',
                    backgroundColor:"transparent"
                }}>
                    <div style={{color:"red", paddingTop: "30px"}}>{(errorMsgs.length > 0)?props.t("COMMON.ERROR"):""}{(errorMsgs.length > 0)?":":""}{errorMsgs.join("\n").replace("Internal server error","系統繁忙，請重試")} &nbsp;</div>
                    <div>
                        <div style={{
                            padding:"10px",height:"4rem",
                        backgroundImage: `url(${input_back})` , backgroundSize:'100% 100%'                        
                        }}>
                        <UsernameEmailField 
                        style={{width:"90%", height: "90%" ,
                        marginTop: "-3px",
                        backgroundColor: "#2C2C2C", color:"#E4E4E4"}}  
                        onChange={onUsernameChange}/>
                        </div>
                        
                        <div style={{padding:"10px",height:"4rem",
                        backgroundImage: `url(${input_back})` , backgroundSize:'100% 100%'
                        }}>
                        <PasswordField
                        style={{width:"90%", height: "90%" ,
                        marginTop: "-3px",
                        backgroundColor: "#2C2C2C", color:"#E4E4E4"}}   
                        onChange={onPasswordChange}/>
                        </div>   

                        <Image style={{
                            width:"30%",
                            color:'#fff',
                            marginTop: "3px",
                            marginBottom: "8px",
                            // height:"2rem",
                            // background: "transparent",
                            // backgroundImage: `url(${iconpwd})` , backgroundSize:'100% 100%'
                            }}
                            src={iconpwd}
                            // compact color={"grey"} size={"tiny"} floated={"center"}
                             as={Link}
                                to='/reset-password'/>
                        <Button
                            style={{
                                height:"4.2rem",
                                background: "transparent",
                                backgroundImage: `url(${iconlogin})` , backgroundSize:'100% 100%'}}
                            fluid size='large' onClick={onSubmit}>
                            &nbsp;
                        </Button>
                    </div>
                </Card>
                <Grid centered>
                <div style={{
                            marginTop:"5px",
                            width:"100%",
                            height:"2px",
                            backgroundImage: `url(${iconline})` , backgroundSize:'100% 100%',
                        }} >&nbsp;</div>
                    <GridRow centered stretched columns={2}>
                        <GridColumn style={{paddingRight: "0rem"}}>
                            <Button
                                style={{
                                    height:"4.2rem",
                                    background: "transparent",
                                    backgroundImage: `url(${icon2})` , backgroundSize:'100% 100%'}}
                                as={Link} to="/signup">&nbsp;</Button>
                        </GridColumn>
                        {
                            (gameId !== "bb20d5cb-2c09-4164-9665-95579d0752ba") &&(
                            <GridColumn style={{paddingLeft: "0rem"}}>
                                <Button
                                    style={{
                                        height:"4.2rem",
                                        background: "transparent",
                                        backgroundImage: `url(${icon1})` , backgroundSize:'100% 100%'}}
                                    onClick={quickSignUp}>&nbsp;</Button>
                            </GridColumn>
                            )
                        }
                    </GridRow>
                </Grid>
            </Form>
        </Container>
    );
}

export default withTrans(LoginFormView);
import React, {Component} from "react";
import {Button, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem} from "../components/lib/ad-manage-api-lib";
import {getUA} from "react-device-detect";
import {navigate} from "../../.cache/gatsby-browser-entry";
import LoginForm from "../components/forms/login-form/login-form";
import logo from "../images/logo.png";

import "../styles/back.css"

export default class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId:'',
            gameId:'',
            accessToken: '',
            nickname:'',
            createdAt:'',
            expireAt:'',
            expireAt_TTL:'',
            eventId:'',
            divStyle:'',
            url:'',
            url1:'',
            iframeKey: 0
        };
        this.state.divStyle = {
            width: '289px',
            borderRadius: '10px 10px 0px 0px',
            display: 'none'
        };
        let { eventId, userId ,gameId, accessToken, nickname, createdAt, expireAt,expireAt_TTL, uuid} = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        this.state.eventId = eventId;
        console.log(uuid);
        let s = decodeURI(uuid);
        s = s.replace(new RegExp('\%3D','g'),"=");
        s = s.replace(new RegExp('\%2F','g'),"/");
        console.log("ccc = " + s);
        this.state.url1 = Buffer.from(s, 'base64').toString('ascii');
        this.state.url1 = this.state.url1.replace(RegExp("@newaccessToken@", "g"), this.state.accessToken);
        // console.log(this.state.url);
        // rejectIfAnyNull(this.props.session, this.props.session);
        // const sUserAgent = navigator.userAgent.toLowerCase();
        // if (getUA.includes("JGGSDKWebView") === false) {
        //     return true;
        // }
        // console.log(getUA);
        // rejectIfAnyNull(this.session, this.session);
    }

    async componentDidMount() {
        // 跳回广告页吧
        // console.log("跳出浏览器了");
        // console.log(this.state.url);
        if (this.state.url1.indexOf("ios.18-jgg.com") !== -1){
            window.location.href = this.state.url1;
        }else{
            this.state.url = this.state.url1;
            this.setState({
                iframeKey: this.state.iframeKey + 1,
                url: this.state.url
            });
            console.log(this.state.url);
        }
    }

    render() {
        let curl = this.state.url;
        if (curl.length <= 10) {
            curl = "about:blank";
        }
        console.log("curl = " + curl);
        return (
            <div style={{position:"absolute",left:"0px",top:"0px", right:"0px",bottom:"0px"}}>
                <header style={{
                    backgroundColor: "#ffffff",
                    color: "#FF3D00",
                    borderBottom: "solid 5px #FF3D00",
                    height: "45px",
                    font: "normal normal 900 22px/40px Segoe UI",
                    paddingLeft: "8px",
                    verticalAlign: "middle"
                }}>
                    <div style={{
                        float: "left"
                    }}>
                        {/*<Image src={logo} style={{*/}
                        {/*    paddingTop: "10px",*/}
                        {/*    verticalAlign: "middle"}}/>*/}
                    </div>
                    <div style={{
                        float: "right"
                    }}>
                    <a href="#"
                       onClick={() => {
                           // navigate(`/sdk-ad-ios/?eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                           window.history.go(-1);
                       }}
                       style={{
                        color: "#ffffff",
                        backgroundColor: "#AFAFAF",
                        borderRadius: "5px",
                        float: "right",
                        width: "50px",
                        height: "30px",
                        lineHeight: "40px",
                        marginTop: "5px",
                        marginRight: "10px",
                        font: "normal normal 600 14px/30px Segoe UI",
                        textAlign: "center",
                        verticalAlign: "middle"
                       }}>返回</a></div>
                </header>
            <div style={{position:"absolute",left:"0px",top:"45px", right:"0px",bottom:"0px"}}>
                <iframe key={this.state.iframeKey} src={this.state.url} style={{width:"100%", height:"100%"}} frameBorder="no" border="0"></iframe>
            </div>
                </div>
    );
    }
};
